<template>
    <v-card class="main-visual rounded-b-0 overflow-hidden" v-if="banner && shows">
        <swiper class="swiper" v-bind="{ options }">
            <template v-for="(slide, index) in banner?.slides">
                <swiper-slide :key="index">
                    <v-card :to="slide?.isOuterLink ? undefined : slide?.url || undefined" :href="slide?.isOuterLink ? slide?.url || undefined : undefined" :target="slide?.target" class="rounded-b-0 overflow-hidden">
                    <!-- <v-card :to="slide?.isOuterLink ? undefined : slide?.url || undefined" :href="slide?.isOuterLink ? slide?.url || undefined : undefined" target="_blank" class="rounded-b-0 overflow-hidden"> -->
                        <div class="main-visual__img" :style="`background-image:url(${slide?.image});`"></div>
                    </v-card>
                </swiper-slide>
            </template>
            <div class="swiper-control" slot="pagination">
                <div class="swiper-pagination"></div>
            </div>
        </swiper>
    </v-card>
</template>

<script>
import api from "@/api";
import detect from "@/plugins/detect";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

const options = {
    allowTouchMove: true,
    centeredSlides: false,
    spaceBetween: 12,
    autoplay: {
        delay: 5000,
        waitForTransition: true,
        disableOnInteraction: false,
    },
    speed: 500,
    lazy: {
        loadPrevNext: true,
    },
    loop: true,
    pagination: {
        el: ".main-visual .swiper-pagination",
        clickable: true,
    },
};

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        code: { type: String, default: null },
        isPc: { type: Boolean, default: false },
        isMb: { type: Boolean, default: false },
    },
    data: () => ({
        banner: null,

        detect,
        options,
    }),
    computed: {
        shows() {
            if (this.isPc) return detect == "pc";
            if (this.isMb) return detect == "mobile";
            return true;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            const { code } = this;
            const { banner } = await api.v1.banners.get({ code });
            this.banner = banner;
        },
        dynamicComponent(url) {
            return (url || "").indexOf("/") == 0 ? "router-link" : "a";
        },
    },
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    max-width: 1030px;
    .swiper-slide {
        width: 100% !important;
    }
    &__img {
        width: 100%;
        padding-top: calc(446 / 1030 * 100%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &-tabs {
        display: flex;
        flex-direction: column;
        height: 100%;
        ::v-deep {
            .v-tabs {
                height: 100%;
            }
            .v-tabs-bar,
            .v-tab {
                height: 100%;
                min-height: 40px;
                max-height: 56px !important;
            }
            .v-tab {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &-tabs {
            ::v-deep {
                .v-tabs-bar,
                .v-tab {
                    min-height: 36px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &-tabs {
            ::v-deep {
                .v-tab {
                    font-size: 16px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}
@media (min-width: 1780px) {
    .main-visual {
        min-height: 446px;
    }
}
</style>
