<template>
    <v-responsive :aspect-ratio="1030 / 446">
        <v-card class="h-100 rounded-b-0 overflow-hidden">
            <v-btn icon class="transparent" style="position: absolute; top: 0; right: 0" @click="$emit('close')"><v-icon size="20" color="white">mdi-close</v-icon></v-btn>
            <v-row no-gutters class="h-100">
                <v-col v-for="item in link" :key="item.title" cols="12" md="4">
                    <div class="main-community pa-20px pa-md-30px pa-lg-40px" :style="'background-image: url('+item.image+');'">
                        <div class="w-100 mb-20px mb-md-40px">
                            <h3 class="tit tit--xs white--text line-height-1">{{item.title}}</h3>
                            <p v-html="item.txt" class="txt txt--sm white--text mt-10px mt-md-20px"></p>
                        </div>
                        <div>
                            <v-btn :to="item.link" v-bind="{ ...btn_secondary2, ...$attrs }">더 보기</v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-responsive>
</template>

<script>
import { btn_secondary2 } from "@/assets/variables";

export default {
    props: {},
    components: {
    },
    data: () => {
        return {
            btn_secondary2,
            link : [
                {
                    title : "새로운 소식",
                    txt : "조이포라이프의 새로운 소식을 <br class='d-none d-lg-block' />만나보세요!",
                    link : "/community/news",
                    image : "/images/main/main-community-bg.jpg",
                },
                {
                    title : "상품리뷰",
                    txt : "구매에서 설치까지 <br class='d-none d-lg-block' />고객님들의 리얼후기!",
                    link : "/shop/reviews",
                    image : "/images/main/main-community-bg2.jpg",
                },
                {
                    title : "셀프가이드",
                    txt : "혼자서도 할 수 있는 꿀팁! <br class='d-none d-lg-block' />이제 나도 할 수 있다!",
                    link : "/center/must-read-guide",
                    image : "/images/main/main-community-bg3.jpg",
                },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.main-community{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
