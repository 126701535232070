<template>
    <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" class="product-item">
        <div class="product-item__inner">
            <like-btn :params="{ _product: value?._id }">
                <template #activator="{ active, toggle }">
                    <v-btn icon small color="grey lighten-3" class="product-item__favorite" @click.stop.capture="toggle">
                        <span v-if="active" class="material-icons-outlined font-size-20 font-size-md-24 primary--text">favorite</span>
                        <span v-else class="material-icons-outlined font-size-20 font-size-md-24 grey--text text--darken-4">favorite_border</span>
                    </v-btn>
                </template>
            </like-btn>
            <router-link :to="`/shop/products/${value._id}`">
                <div class="product-item__img" :style="`background-image:url(${value.thumb?.url});`">
                    <div v-if="!value.sells || value.stock == 0" class="product-item__badge">Sold Out</div>
                    <v-chip-group v-if="0 < labels?.length" column style="position: absolute; top: 12px; left: 16px; max-width: calc(100% - 16px - 36px - 16px)">
                        <template v-for="item in labels">
                            <v-chip dark :color="`${item.color}`" x-small :key="item.value">
                                {{ item.text }}
                            </v-chip>
                        </template>
                    </v-chip-group>
                    <!-- 개발확인 -->
                    <!-- <div class="product-item__color-option">
                        3개 미만
                        <div>
                            <v-row align="center" class="row--xs">
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #9c6767" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #b1a321" />
                                </v-col>
                            </v-row>
                        </div>
                        3개 이상
                        <div>
                            <v-row align="center" class="row--xs">
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #d1d1d8" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #b1aca6" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="product-item__color-chip" style="background-color: #c2c0b5" />
                                </v-col>
                                <v-col cols="auto">
                                    <div class="txt txt--xs white--text">+13</div>
                                </v-col>
                            </v-row>
                        </div>
                    </div> -->
                </div>
                <div class="product-item__con">
                    <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="value?.flags?.length">
                        {{ value?.flags?.map((text) => `[${text}]`).join(" ") }}
                    </div>
                    <div class="product-item__tit">
                        <span>{{ value.name }} {{ value.code || "" }}</span>
                    </div>
                    <div v-if="0 < value?.meta?.sales?.count" class="mt-2 mb-n2 text-caption grey--text text--lighten-2">
                        <span>판매건수 {{ value?.meta?.sales?.count }}</span>
                    </div>
                    <!-- <div class="mt-10px mt-md-14px">
                        <div class="d-flex align-center">
                            <v-rating half-increments readonly size="18" color="grey darken-4" background-color="grey darken-4" :value="+(value?.meta?.reviews?.star__average || 0).toFixed(1)" /> -->
                    <!-- <span class="material-icons-outlined primary--text">star</span> -->
                    <!-- <span class="font-size-14 font-weight-medium grey--text">{{ (value?.meta?.reviews?.star__average || 0).toFixed(1) }}</span> -->
                    <!-- <span class="font-size-14 grey--text text--lighten-1 pl-4px">({{ value?.meta?.reviews?.count__total?.format?.() || 0 }})</span>
                        </div>
                    </div> -->
                    <div class="mt-10px mt-md-16px">
                        <!-- <template>
                            <div class="font-size-14 grey--text">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">판매건수</v-col>
                                    <v-col cols="auto">36</v-col>
                                </v-row>
                            </div>
                        </template> -->
                        <span v-if="discountPrice" class="font-size-14 grey--text text--lighten-1 text-decoration-line-through">{{ value.price.format() }}</span>
                        <em v-if="!value.sells || value.stock == 0" class="font-size-14 font-size-md-16 grey--text text--lighten-1">품절</em>
                        <div v-else class="d-flex flex-wrap align-center font-size-18 font-size-lg-24 font-weight-bold">
                            <span v-if="discountRate" class="secondary--text mr-4px mr-md-8px">{{ discountRate }}%</span>
                            <span class="d-flex flex-wrap align-center grey--text text--darken-4">{{ salePrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                        </div>
                    </div>
                    <!-- <div class="mt-4px mt-md-8px">
                        <v-row class="row--xxs">
                            <v-col v-if="value.NEW_ARRIVAL" cols="auto"><v-chip small color="grey lighten-5" text-color="primary">신상품</v-chip></v-col>
                            <v-col v-if="value.POPULAR" cols="auto"><v-chip small color="grey lighten-5" text-color="secondary">POPULAR</v-chip></v-col>
                            <v-col cols="auto"><v-chip small color="grey lighten-5" text-color="grey darken-4">당일배송</v-chip></v-col>
                        </v-row>
                    </div> -->
                </div>
            </router-link>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from "vuex";

import LikeBtn from "@/components/client/like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        value: { type: Object, default: null },

        cols: { type: String, default: "6" },
        sm: { type: String, default: "4" },
        md: { type: String, default: "4" },
        lg: { type: String, default: "3" },
    },
    computed: {
        ...mapGetters(["getDiscountPrice", "getDiscountRate", "getSalePrice", "mapProductLabels"]),
        discountPrice() {
            return this.getDiscountPrice(this.value);
        },
        discountRate() {
            return this.getDiscountRate(this.value);
        },
        salePrice() {
            return this.getSalePrice(this.value);
        },
        labels() {
            return this.mapProductLabels(this.value?.labels).filter(({ shows } = {}) => shows);
        },
    },
};
</script>
